//==============================================
// @footer
//==============================================
.footer 
    width: 100%
    height: 120px
    position: absolute
    bottom: 0
    border-top: 2px solid #ffdd69
    background-color: #b11f1f
    color: #fff
    font-size: 12px
    

.fo-table   
    display: table
    width: 100%
    height: 100%
    text-align: center
    .fo-cell
        display: table-cell
        vertical-align: middle   
        height: 131px
    .fo-con
        text-align: center
        display: inline-block
        *zoom: 1
        *display: inline
        vertical-align: middle
        .fo-con-txt
            margin: 15px 0px
        img
            margin-right: 30px
