//==============================================
// @common
//==============================================

*, *:before, *:after
    -webkit-box-sizing: border-box
    -ms-box-sizing: border-box
    box-sizing: border-box

html
    text-size-adjust: 100%
    font-size: 62.5%
    height: 100%

body
    font-size: 14px
    line-height: 1.5
    color: $txt
    font-family: $yh
    height: 100%
    background-color: #fff
a
    color: $txt

.fl
    float: left

.fr
    float: right
.w
    width: 1200px
    margin: 0px auto
.clear
    &:after
        display: table
        clear: both
        overflow: hidden
        content: ""
        *zoom: 1
table 
    width: 100%
.blue
    color: #367abd
.orange
    color: $orange
.green
    color: $green
.grey
    color: #999999

// for 在高度不足一屏情况下，让底部固定在最下方
html, body
    height: 100%
#wrapper
    min-height: 100%
    position: relative
    height: auto
    padding-bottom: 120px

