@charset "UTF-8"

@import _/var
.guide
    line-height: 30px
    background-color: #fff
    a
        color: #999
        font-size: 12px
        height: 30px
        padding-left: 25px
        +bgp(home,left)
        &:hover
            color: $main
    .guidefr
        span
            width: 1px
            height: 12px
            background-color: #999
            margin:  9px 10px
        
    


.top
    +bg(topbg,center)
    height: 151px
    border-top: 1px solid $main
    .logo 
        font-size: 0px
        line-height: 140px
        img 
            vertical-align: middle
    .topbg
        height: 150px
    .search
        height: 36px
        border: 1px solid #cccccc
        line-height: 34px
        background-color: #fff
        margin-top: 60px
        +br(18px)
        .ipt
            height: 100%
            *height: 34px
            *line-height: 34px
            border: 0
            width: 207px
            padding-left: 20px
            font-size: 12px
            background-color: #fff
            +br(18px 0px 0px 18px)
            outline: none
            &::-webkit-input-placeholder
                color: #999
        .submit
            border: 0
            height: 100%
            *height: 34px
            width: 51px
            color: #fff
            font-size: 14px
            +bgp(search,center)
            background-color: #fff
            +br(0px 18px 18px 0px)
            +fix(transition,all 400ms ease)
            outline: none
            &:hover
                +bgp(search-w,center)
                background-color: $main

.header
    background-color: $main
    ul
        li 
            float: left
            width: 100px
            height: 44px
            line-height: 44px
            text-align: center
            position: relative
            *zoom: 1
            &:hover
                &>a 
                    background-color: #9b0404
                .list-se
                    display: block
  

            a
                display: block
                color: #fff
    .list-se
        line-height: 1
        position: absolute
        top: 44px
        left: 0
        width: 170px
        z-index: 9999
        display: none
        background-color: #d1a544
        opacity: 0.8
        *zoom: 1

        a 
            line-height: 40px
            font-size: 14px
            padding-left: 26px
            text-align: left
            border-top-color: rgb(218, 218, 218)
            border-top-width: 1px
            border-top-style: dotted
            &:first-child
                border-top: 0
            &:hover
                background-color: #9b0404




                









    



