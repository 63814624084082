@import _/var
@import c/reset
@import c/common
@import m/header
@import m/footer
select 
    appearance: none
    -moz-appearance: none
    -webkit-appearance: none
select::-ms-expand  
    display: none